import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ContentWrapper } from '../../components/common/wrappers/Wrappers'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { breakpoint, color } from '../../configs/utilities'
import ButtonLink from '../../components/common/buttons/ButtonLink'
import { SectionText } from '../../components/common/typography/Typography'

const HeaderSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    {
      profile: file(relativePath: { eq: "resume/profile.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 70
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            breakpoints: [768, 1380]
          )
        }
      }
    }
  `)
  return (
    <ContentWrapper>
      <Wrapper>
        <Top>
          <Profile>
            <GatsbyImage image={data.profile.childImageSharp.gatsbyImageData} alt="logo" />
            <ProfileText>
              <span className="profileName">{intl.formatMessage({ id: 'resume.name' })}</span>
              <span className="profileOccupation">{intl.formatMessage({ id: 'resume.occupation' })}</span>
            </ProfileText>
          </Profile>
          <ButtonLink external download to="/resume-ag.pdf" buttonText={intl.formatMessage({ id: 'resume.download' })} buttonBackground={color.blue} buttonTextColor={color.white}/>
        </Top>
        <About>
          <SectionText>{intl.formatMessage({ id: 'resume.about' })}</SectionText>
          <SectionText>{intl.formatMessage({ id: 'resume.add' })}</SectionText>
        </About>
      </Wrapper>
    </ContentWrapper>
  )
}

export default injectIntl(HeaderSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

const Top = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Profile = styled.div`
  display: flex;
  img {
    width: 70px;
  }
  @media screen and (max-width: ${breakpoint.xsm}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const ProfileText = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: ${breakpoint.xsm}) {
    align-items: center;
    text-align: center;
  }
  .profileName {
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 28px;
    }
  }
  .profileOccupation {
    margin-top: 5px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 16px;
    }
  }
`

const About = styled.div``
