import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { ContentWrapper } from '../../components/common/wrappers/Wrappers'
import { breakpoint, color, transition, opacity } from '../../configs/utilities'
import { SectionText } from '../../components/common/typography/Typography'

const ExperienceSection = ({ intl }) => {
  return (
    <ContentWrapper>
      <Wrapper>
        <Section>
          <Header>{intl.formatMessage({ id: 'resume.experience.header' })}</Header>
          <ArticleWrapper>
            <Article>
              <Year>{intl.formatMessage({ id: 'resume.experience.update01.year' })} </Year>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.update01.role' })}</Bold>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.update01.company01' })}</Bold>
              <Bold>{intl.formatMessage({ id: 'resume.experience.update01.company02' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.experience.present.location' })}</Regular>
            </Article>
            <Text>
              <SectionText>{intl.formatMessage({ id: 'resume.experience.update01.text01' })}</SectionText>
              <SectionText>{intl.formatMessage({ id: 'resume.experience.update01.text02' })}</SectionText>
            </Text>
          </ArticleWrapper>
          <ArticleWrapper>
            <Article>
              <Year>{intl.formatMessage({ id: 'resume.experience.present.year' })} </Year>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.present.role' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.experience.present.position' })}</Regular>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.present.company' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.experience.present.location' })}</Regular>
              <Link href="https://amibo.rs/" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'resume.experience.present.site' })}
              </Link>
            </Article>
            <Text>
              <SectionText>{intl.formatMessage({ id: 'resume.experience.present.text01' })}</SectionText>
              <SectionText>{intl.formatMessage({ id: 'resume.experience.present.text02' })}</SectionText>
            </Text>
          </ArticleWrapper>
          <ArticleWrapper>
            <Article>
              <Year>{intl.formatMessage({ id: 'resume.experience.founder.year' })}</Year>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.founder.role' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.experience.founder.position' })}</Regular>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.founder.company' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.experience.founder.location' })}</Regular>
            </Article>
            <Text>
              <SectionText>{intl.formatMessage({ id: 'resume.experience.founder.text01' })}</SectionText>
            </Text>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.founder.external.uzice.role' })}</Bold>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.founder.external.uzice.company' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.experience.founder.external.uzice.location' })}</Regular>
              <Link href="https://www.textilue.rs/" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'resume.experience.founder.external.uzice.site' })}
              </Link>
              <Text>
                <SectionText>
                  {intl.formatMessage({ id: 'resume.experience.founder.external.uzice.text01' })}
                </SectionText>
              </Text>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.founder.external.jagodina.role' })}</Bold>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.founder.external.jagodina.textil.company' })}</Bold>
              <Regular>
                {intl.formatMessage({ id: 'resume.experience.founder.external.jagodina.textil.location' })}
              </Regular>
              <Link href="https://www.textil.rs/" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'resume.experience.founder.external.jagodina.textil.site' })}
              </Link>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.founder.external.jagodina.baby.company' })}</Bold>
              <Regular>
                {intl.formatMessage({ id: 'resume.experience.founder.external.jagodina.baby.location' })}
              </Regular>
              <Regular>
                {intl.formatMessage({ id: 'resume.experience.founder.external.jagodina.baby.explain' })}
              </Regular>
              <Link href="https://www.babytextil.rs/" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'resume.experience.founder.external.jagodina.baby.site' })}
              </Link>
              <Text>
                <SectionText>
                  {intl.formatMessage({ id: 'resume.experience.founder.external.jagodina.baby.text' })}
                </SectionText>
              </Text>
            </Article>
          </ArticleWrapper>
          <ArticleWrapper>
            <Article>
              <Year>{intl.formatMessage({ id: 'resume.experience.junior.year' })}</Year>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.junior.role' })}</Bold>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.junior.company' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.experience.junior.location' })}</Regular>
              <Regular>{intl.formatMessage({ id: 'resume.experience.junior.explain' })}</Regular>
            </Article>
            <Text>
              <SectionText>{intl.formatMessage({ id: 'resume.experience.junior.text01' })}</SectionText>
              <SectionText>{intl.formatMessage({ id: 'resume.experience.junior.text02' })}</SectionText>
            </Text>
            <Article>
              <Regular>{intl.formatMessage({ id: 'resume.experience.junior.collections' })}</Regular>
            </Article>
            <ListWrapper>
              <ListItemSmall>{intl.formatMessage({ id: 'resume.experience.junior.collection01' })}</ListItemSmall>
              <ListItemSmall>{intl.formatMessage({ id: 'resume.experience.junior.collection02' })}</ListItemSmall>
            </ListWrapper>
          </ArticleWrapper>
          <ArticleWrapper>
            <Article>
              <Year>{intl.formatMessage({ id: 'resume.experience.internship.year' })}</Year>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.internship.role' })}</Bold>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.experience.internship.company' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.experience.internship.location' })}</Regular>
              <Regular>{intl.formatMessage({ id: 'resume.experience.internship.explain' })}</Regular>
            </Article>
            <Article>
              <Regular>{intl.formatMessage({ id: 'resume.experience.internship.text' })}</Regular>
            </Article>
          </ArticleWrapper>
        </Section>

        <Section>
          <Header>{intl.formatMessage({ id: 'resume.education.header' })}</Header>
          <ArticleWrapper>
            <Article>
              <Year>{intl.formatMessage({ id: 'resume.education.faculty.year' })}</Year>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.education.faculty.name' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.education.faculty.degree' })}</Regular>
            </Article>
            <Article>
              <Link href="https://www.fpu.bg.ac.rs/" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'resume.education.faculty.site' })}
              </Link>
            </Article>
          </ArticleWrapper>
          <ArticleWrapper>
            <Article>
              <Year>{intl.formatMessage({ id: 'resume.education.school.year' })}</Year>
            </Article>
            <Article>
              <Bold>{intl.formatMessage({ id: 'resume.education.school.name' })}</Bold>
              <Regular>{intl.formatMessage({ id: 'resume.education.school.degree' })}</Regular>
            </Article>
            <Article>
              <Link href="http://www.skolazadizajn.com/" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'resume.education.school.site' })}
              </Link>
            </Article>
          </ArticleWrapper>
        </Section>

        <Section>
          <Header>{intl.formatMessage({ id: 'resume.skills.header' })}</Header>
          <ArticleWrapper>
            <ListWrapper>
              <ListItem>{intl.formatMessage({ id: 'resume.skills.skill01' })}</ListItem>
              <ListItem>{intl.formatMessage({ id: 'resume.skills.skill02' })}</ListItem>
              <ListItem>{intl.formatMessage({ id: 'resume.skills.skill03' })}</ListItem>
              <ListItem>{intl.formatMessage({ id: 'resume.skills.skill04' })}</ListItem>
            </ListWrapper>
          </ArticleWrapper>
        </Section>

        <Section>
          <Header>{intl.formatMessage({ id: 'resume.hobbies.header' })}</Header>
          <ArticleWrapper>
            <Regular>{intl.formatMessage({ id: 'resume.hobbies.therapy' })}</Regular>
            <ListWrapper>
              <ListItem>{intl.formatMessage({ id: 'resume.hobbies.hobby01' })}</ListItem>
              <ListItem>{intl.formatMessage({ id: 'resume.hobbies.hobby02' })}</ListItem>
              <ListItem>{intl.formatMessage({ id: 'resume.hobbies.hobby03' })}</ListItem>
              <ListItem>{intl.formatMessage({ id: 'resume.hobbies.hobby04' })}</ListItem>
              <ListItem>{intl.formatMessage({ id: 'resume.hobbies.hobby05' })}</ListItem>
              <ListItem>{intl.formatMessage({ id: 'resume.hobbies.hobby06' })}</ListItem>
            </ListWrapper>
          </ArticleWrapper>
        </Section>
      </Wrapper>
    </ContentWrapper>
  )
}

export default injectIntl(ExperienceSection)

const Wrapper = styled.div`
  @media screen and (min-width: ${breakpoint.md}) {
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -30px; // decrease SectionWrapper padding
  margin-bottom: 70px;

  :last-child {
    margin-bottom: 0;
  }
`

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 20px 0 10px;
`

const Article = styled.div`
  display: flex;
  flex-direction: column;
`
const Text = styled.div``

const Header = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: ${color.blue};
  border-bottom: 1px solid ${color.blue};
  padding: 10px 0;
`
const Year = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${color.blue};
`

const Bold = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

const Regular = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
`

const Link = styled.a`
width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${color.blue};
  transition: ${transition.default};
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      opacity: ${opacity.default};
    }
  }
`

const ListWrapper = styled.ul`
  padding: 5px 30px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`

const ListItem = styled.li`
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
`

const ListItemSmall = styled.li`
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  font-style: italic;
`