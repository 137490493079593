import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout/Layout'
import HeaderSection from '../sections/resume/HeaderSection'
import ExperienceSection from '../sections/resume/ExperienceSection'

const Resume = () => {
  return (
    <Layout pageName="resume">
      <Wrapper>
      <HeaderSection/>
      <ExperienceSection/>
      </Wrapper>
    </Layout>
  )
}

export default Resume

const Wrapper = styled.div``